const API_ROOT = '/api-app/v3';
const ADMIN_ROOT = `${API_ROOT}/admin`;

const ACCOUNT_ROOT = `${API_ROOT}/account`;
const RESTAURANT_ROOT = `${API_ROOT}/restaurant`;
const CALENDAR_ROOT = `${API_ROOT}/calendar`;
const UPLOAD_ROOT = `${API_ROOT}/upload`;
const CONFIG_ROOT = `${API_ROOT}/config`;
const SETTINGS_ROOT = `${RESTAURANT_ROOT}/settings`;
const RESERVATIONS_ROOT = `${RESTAURANT_ROOT}/reservations`;
const RESERVATION_ROOT = `${RESTAURANT_ROOT}/reservation`;
const TIME_MANAGEMENT = `${RESTAURANT_ROOT}/time-management`;
const TABLE_MANAGEMENT = `${RESTAURANT_ROOT}/table-management`;
const OPENING_HOURS = `${RESTAURANT_ROOT}/opening-hours`;

export const ENDPOINT_URLS = {
  ACCOUNT: {
    ROOT: ACCOUNT_ROOT,
    USER: {
      ROOT: `${ACCOUNT_ROOT}/user`,
      USERS: `${ACCOUNT_ROOT}/users`,
    },
    PROFILE: {
      ROOT: `${ACCOUNT_ROOT}/profile`,
    },
    OWNER: {
      ROOT: `${ACCOUNT_ROOT}/owner`,
    },
    WAITER: {
      // ROOT: `${ACCOUNT_ROOT}/waiter`,
    },
  },
  ADMIN: {
    EMAIL: {
      TEMPLATES: `${ADMIN_ROOT}/email/templates`,
    },
    LOGS: {
      EMAIL: `${ADMIN_ROOT}/logs/send-mail`,
      ERROR: `${ADMIN_ROOT}/logs/error`,
    },
    SYSTEM: {
      ERROR_LOG_CONTEXT: `${ADMIN_ROOT}/system/error-log-context`,
      RESTAURANT_ROOT: `${ADMIN_ROOT}/system/restaurant-names`,
      OWNER_NAMES: `${ADMIN_ROOT}/system/owner-names`,
      EMAIL_TYPES: `${ADMIN_ROOT}/system/email-types`,
      ENUM_OPTIONS: `${ADMIN_ROOT}/system/restaurant-config-options`,
      EMAIL_SEND_STATUSES: `${ADMIN_ROOT}/system/email-send-statuses`,
      WIDGET_ROOT: `${ADMIN_ROOT}/system/widget-group-names`,
    },
    RESTAURANT: {
      ROOT: `${ADMIN_ROOT}/restaurants`,
    },
    RESERVATION: {
      ROOT: `${ADMIN_ROOT}/reservations`,
    },
    USER: {
      ROOT: `${ADMIN_ROOT}/users`,
    },
    ACCOUNT: {
      ROOT: `${ADMIN_ROOT}/accounts`,
    },
    APPLICATION: {
      DB_CACHE: `${ADMIN_ROOT}/application/db-cache`,
      CRON: {
        LEGACY_QUEUE: `${ADMIN_ROOT}/application/cron/legacy-queue`,
        LEGACY_FAILED_QUEUE: `${ADMIN_ROOT}/application/cron/legacy-failed-jobs-queue`,
        MAIL_QUEUE: `${ADMIN_ROOT}/application/cron/mail-queue`,
        SCHEDULE_JOBS: `${ADMIN_ROOT}/application/cron/schedule-job-queue`,
      },
    },
    WIDGET_THEMES: {
      THEME: {
        ROOT: `${ADMIN_ROOT}/widget-themes`,
      },
      THEME_GROUPS: {
        ROOT: `${ADMIN_ROOT}/widget-theme-groups`,
      },
    },
  },
  RESTAURANT: {
    ROOT: RESTAURANT_ROOT,
    ROOMS: `${TABLE_MANAGEMENT}/rooms`,
    TABLES: `${TABLE_MANAGEMENT}/tables`,
    SPECIAL_OFFERS: `${RESTAURANT_ROOT}/special-offers`,
    TIME_MANAGEMENT: {
      DEFAULT_BOOKING_LENGTH: `${TIME_MANAGEMENT}/default-booking-length`,
      CUSTOM_BOOKING_LENGTH: `${TIME_MANAGEMENT}/custom-booking-length`,
      CUSTOM_BOOKING_LENGTH_ORDER: `${TIME_MANAGEMENT}/custom-booking-length/order`,
    },
    AVAILABLE_SLOTS: {
      SLOTS_DETAILS: `${RESTAURANT_ROOT}/available-slots/slots-details`,
    },
    TABLE_MANAGEMENT: {
      LAYOUTS: `${TABLE_MANAGEMENT}/layouts`,
      ROOMS: `${TABLE_MANAGEMENT}/rooms`,
      ROOMS_ORDER: `${TABLE_MANAGEMENT}/rooms/order`,
      TABLES: `${TABLE_MANAGEMENT}/tables`,
      GROUPS: `${TABLE_MANAGEMENT}/groups`,
    },
    CLIENT_SEARCH: `${RESTAURANT_ROOT}/search/reservation/client`,
    RESERVATIONS_SEARCH: `${RESTAURANT_ROOT}/search/reservation`,
    RESERVATION: {
      ROOT: RESERVATION_ROOT,
      COMMENT: `${RESERVATION_ROOT}/comment`,
      RESERVATIONS: {
        ROOT: RESERVATIONS_ROOT,
        DETAILS: `${RESERVATIONS_ROOT}/details`,
      },
      SLOT: {
        ROOT: `/api-app/v3/restaurant/available-slots/slots-details`,
      },
      STATISTICS: {
        CHANNELS: `${RESERVATION_ROOT}/statistics/channels`,
        ORIGINS: `${RESERVATION_ROOT}/statistics/origins`,
      },
    },
    CLIENT: {
      ROOT: `${RESTAURANT_ROOT}/client`,
    },
    OPENING_HOURS: {
      ROOT: OPENING_HOURS,
      WEEKDAY: {
        INDEX: `${OPENING_HOURS}/weekday`,
      },
      PERIOD: {
        INDEX: `${OPENING_HOURS}/period`,
      },
    },
    SETTINGS: {
      WIDGET: `${SETTINGS_ROOT}/widget`,
      CALENDAR: `${SETTINGS_ROOT}/calendar`,
      TIME_AND_LANGUAGE: `${SETTINGS_ROOT}/time-language`,
      OPENING_HOURS: `${SETTINGS_ROOT}/opening-hours`,
      POLICIES_AND_TERMS: `${SETTINGS_ROOT}/policies-terms`,
      WIDGET_INTEGRATIONS: `${SETTINGS_ROOT}/widget-integrations`,
      WIDGET_FIELDS: `${SETTINGS_ROOT}/widget-fields`,
      NOTIFICATION: {
        SMS: `${SETTINGS_ROOT}/notification/sms`,
        EMAIL: `${SETTINGS_ROOT}/notification/email`,
        FEEDBACK: `${SETTINGS_ROOT}/notification/feedback`,
        DEFAULT_TEMPLATE: `${SETTINGS_ROOT}/notification/email/default-template`,
        EMAIL_PREVIEW: '/email/preview',
      },
      MARKETING_TOOLS: {
        INTEGRATIONS: `${SETTINGS_ROOT}/integrations`,
      },
    },
  },
  PARTNER: {
    MOZREST: {
      ROOT: `${API_ROOT}/partner/mozrest`,
      CHANNEL: `${API_ROOT}/partner/mozrest/channel`,
    },
  },
  FILE: {
    ROOT: UPLOAD_ROOT,
    STORAGE: '/storage',
  },
  CALENDAR: {
    ROOT: `${CALENDAR_ROOT}/reservation`,
  },
  CONFIG: {
    ROOT: CONFIG_ROOT,
  },
};
