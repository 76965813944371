import { ReactComponent as HomeIcon } from 'images/icons/ic-home.svg';
import { ReactComponent as TabletIcon } from 'images/icons/ic-tablet.svg';
import { ReactComponent as HourglassIcon } from 'images/icons/ic-hourglass.svg';
import { ReactComponent as UserIcon } from 'images/icons/ic-user.svg';
import { ReactComponent as SettingsIcon } from 'images/icons/ic-settings-icon.svg';
import { ReactComponent as LayoutGridIcon } from 'images/icons/ic-layout-grid.svg';
import { ReactComponent as CodeIcon } from 'images/icons/ic-code-snippet.svg';
import { ReactComponent as LayoutLeftIcon } from 'images/icons/ic-layout-left.svg';
import { ReactComponent as MailIcon } from 'images/icons/ic-mail.svg';
import { ReactComponent as EditIcon } from 'images/icons/ic-edit.svg';
import { ReactComponent as CommentIcon } from 'images/icons/ic-message-notification.svg';
import { ReactComponent as AlertIcon } from 'images/icons/ic-alert-circle.svg';
import { ReactComponent as MessageIcon } from 'images/icons/ic-message-smile-circle.svg';
import { ReactComponent as CoinIcon } from 'images/icons/ic-coins-stacked.svg';
import { ReactComponent as LifeBouyIcon } from 'images/icons/ic-life-buoy.svg';
import { ReactComponent as GalleryIcon } from 'images/icons/ic-image-plus.svg';
import { ReactComponent as MenuIcon } from 'images/icons/ic-menu-editor.svg';
import { ReactComponent as UsersSecondIcon } from 'images/icons/ic-users-icon.svg';
import { ReactComponent as UsersPlusIcon } from 'images/icons/ic-users-plus.svg';
import { ReactComponent as InboxIcon } from 'images/icons/ic-inbox.svg';
import { ReactComponent as SpecialStarIcon } from 'images/icons/ic-special-star.svg';
import { ReactComponent as BookmarkIcon } from 'images/icons/ic-bookmark-add.svg';
import { ReactComponent as HelpSquareIcon } from 'images/icons/ic-help-square.svg';
import { ReactComponent as PhoneIcon } from 'images/icons/ic-phone-call.svg';
import { oldAppUrlGenerate } from 'utils/data-processors/url';
import { TFunction } from 'i18next';
import paths from 'app/router/paths';
import { Access } from 'hooks/useGuard';

export const settingsSubNavigationLinks = (t: TFunction) => [
  {
    title: t('settingsAccountSetup'),
    links: [
      {
        icon: <HomeIcon />,
        to: paths.settings.accountSetup.restaurantInformation,
        access: Access.ManageRestaurantInfo,
        name: t('settingsBasicInformation'),
      },
      {
        icon: <HourglassIcon />,
        to: paths.settings.accountSetup.timeAndLanguage,
        access: Access.ManageTimeAndLanguage,
        name: t('settingsTimeAndLanguage'),
      },
      {
        icon: <UserIcon />,
        to: paths.settings.accountSetup.ownerAccount,
        access: Access.ManageOwnerAccount,
        name: t('settingsManagerAccount'),
      },
      {
        icon: <UsersSecondIcon />,
        to: paths.settings.accountSetup.users,
        access: Access.ManageUsers,
        name: t('settingsUsers'),
      },
    ],
  },
  {
    title: t('settingsTableSettings'),
    links: [
      {
        icon: <SettingsIcon />,
        to: paths.settings.tableSettings.tableManagement,
        access: Access.ManageDiningRoomsAndAreas,
        name: t('settingsDiningRoomsAndTables'),
      },
      {
        icon: <LayoutGridIcon />,
        to: paths.settings.tableSettings.tableGroups,
        access: Access.ManageTableGroups,
        name: t('settingsTableGroups'),
      },
      {
        icon: <HourglassIcon />,
        to: paths.settings.accountSetup.timeManagement,
        access: Access.ManageReservationDuration,
        name: t('settingsTimeManagement'),
      },
      {
        icon: <TabletIcon />,
        to: oldAppUrlGenerate('settings/floor-plan', false),
        access: Access.ManageFloorPlan,
        name: t('settingsFloorPlanSetup'),
      },
    ],
  },
  {
    title: t('settingsTimeManagement'),
    links: [
      {
        icon: <HourglassIcon />,
        to: oldAppUrlGenerate('settings/opening-hours', false),
        // to: paths.settings.openingHours.weekdaySettings,
        access: Access.ManageOpeningHours,
        name: t('settingsOpeningHours'),
      },
      {
        icon: <CodeIcon />,
        to: oldAppUrlGenerate('settings/opening-hours', false),
        // to: paths.settings.openingHours.periodSetting,
        name: t('settingsCustomPeriods'),
        access: Access.ManageOpeningHours,
      },
    ],
  },
  {
    title: t('settingsCalendarAndListSettings'),
    links: [
      {
        icon: <SettingsIcon />,
        to: paths.settings.calendarAndList.settings,
        access: Access.ManageCalendarSettings,
        name: t('settingsWebsiteSettings'),
      },
    ],
  },
  {
    title: t('settingsNotifications'),
    links: [
      {
        icon: <MailIcon />,
        to: paths.settings.notifications.emailSettings,
        name: t('settingsEmailSettings'),
        access: Access.ManageEmailSettings,
      },
      {
        icon: <EditIcon />,
        to: oldAppUrlGenerate('settings/sms-notifications', false),
        access: Access.ManageSmsSettings,
        name: t('settingsSMSSettings'),
      },
      {
        icon: <CommentIcon />,
        to: paths.settings.notifications.feedbackSettings,
        name: t('settingsFeedbackSettings'),
        access: Access.ManageFeedbackSettings,
      },
    ],
  },
  {
    title: t('settingsOnlineReservations'),
    links: [
      {
        icon: <SettingsIcon />,
        to: paths.settings.onlineReservations.widgetSettings,
        access: Access.ManageWidgetSettings,
        name: t('settingsWidgetSettings'),
      },
      {
        icon: <SettingsIcon />,
        to: paths.settings.onlineReservations.widgetFields,
        access: Access.ManageWidgetFields,
        name: t('settingsWidgetFields'),
      },
      {
        icon: <LayoutLeftIcon />,
        to: paths.settings.onlineReservations.policiesAndTerms,
        access: Access.ManagePoliciesAndTerms,
        name: t('settingsPoliciesAndTerms'),
      },
      {
        icon: <CodeIcon />,
        to: paths.settings.onlineReservations.widgetIntegration.index,
        access: Access.ManageWidgetIntegration,
        name: t('settingsWidgetIntegration'),
      },
      // {
      //   icon: <AnnouncementIcon />,
      //   to: '/',
      //   name: t('settingsMarketingChannels'),
      //   access: Access.ManageMarketingChannels,
      // },
    ],
  },

  {
    title: t('settingsTools'),
    links: [
      {
        icon: <AlertIcon />,
        // to: paths.settings.marketingTools.experiences.index,
        to: oldAppUrlGenerate('settings/prepayments-and-promotions', false),
        // access: Access.ManageExperiences,
        name: t('settingsExperiences'),
      },
      {
        icon: <MessageIcon />,
        // to: paths.settings.marketingTools.events.index,
        to: oldAppUrlGenerate('settings/prepayments-and-promotions', false),
        // access: Access.ManageEvents,
        name: t('settingsEvents'),
      },
      {
        icon: <SpecialStarIcon />,
        // to: paths.settings.marketingTools.specialOffers.index,
        to: oldAppUrlGenerate('settings/prepayments-and-promotions', false),
        // access: Access.ManageSpecialOffers,
        name: t('settingsSpecialOffers'),
      },
      {
        icon: <CoinIcon />,
        // to: PATH.DEPOSITS,
        to: oldAppUrlGenerate('settings/prepayments-and-promotions', false),
        access: Access.ManagePrepaymentsAndDeposits,
        name: t('settingsPrepaymentsAndDeposits'),
      },
      {
        icon: <LifeBouyIcon />,
        to: paths.settings.marketingTools.thirdPartyIntegrations.index,
        access: Access.Manage3rdPartyIntegrations,
        name: t('settings3rdPartyIntegrations'),
      },
      {
        icon: <BookmarkIcon />,
        to: '/',
        name: t('settingsBanners'),
        access: Access.ManageBanners,
      },
    ],
  },
  {
    title: t('settingsWebsiteBuilder'),
    links: [
      {
        icon: <SettingsIcon />,
        to: oldAppUrlGenerate('settings/website', false),
        name: t('settingsRestaurantProfile'),
        // access: Access.ManageRestaurantProfile,
      },
      {
        icon: <GalleryIcon />,
        to: oldAppUrlGenerate('settings/website', false),
        name: t('settingsWebsiteGallery'),
        // access: Access.ManageGallery,
      },
      {
        icon: <CodeIcon />,
        to: '/',
        name: t('settingsWebsiteMenuSettings'),
        access: Access.ManageMenuSettings,
      },
      {
        icon: <MenuIcon />,
        to: '/',
        name: t('settingsWebsiteMenuEditor'),
        access: Access.ManageMenuEditor,
      },
    ],
  },
  {
    title: t('settingsSubscription'),
    links: [
      {
        icon: <UsersPlusIcon />,
        to: oldAppUrlGenerate('settings/subscription', false),
        access: Access.ManageSubscriptionAndBilling,
        name: t('settingsSubscriptionAndBillingData'),
      },
      {
        icon: <InboxIcon />,
        to: oldAppUrlGenerate('settings/invoices', false),
        access: Access.ViewInvoices,
        name: t('settingsInvoices'),
      },
    ],
  },
  {
    title: t('settingsSupport'),
    links: [
      {
        icon: <HelpSquareIcon />,
        to: '/',
        name: t('settingsChat'),
        disabled: true,
      },
      {
        icon: <HelpSquareIcon />,
        to: 'https://help.tablein.com/',
        target: '_blank',
        name: t('settingsHelpPages'),
      },
      {
        icon: <PhoneIcon />,
        to: 'https://help.tablein.com/contact-tablein',
        target: '_blank',
        name: t('settingsContactTablein'),
      },
    ],
  },
];
