import CronJobCard from 'app/pages/Admin/Application/Cron/_components/CronJobCard';
import { useEffect, useState } from 'react';
import { getAdminLegacyQueueItemsCall } from 'api/app/admin/application/cron';
import { Box, Grid, Tooltip, Typography } from '@mui/material';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import JobItemsEmptyState from 'app/pages/Admin/Application/Cron/_components/JobItemsEmptyState';
import { LegacyQueueJobItem } from 'app/pages/Admin/Application/Cron/index';

type LegacyQueueResponse = {
  is_running: boolean;
  jobs: LegacyQueueJobItem[];
};

const LegacyQueuedJobsCard = () => {
  const { t } = useTranslation();
  const [queueItems, setQueueItems] = useState<LegacyQueueJobItem[]>([]);
  const [status, setStatus] = useState<'idle' | 'running'>('idle');
  const [isLoading, setIsLoading] = useState(true);

  const fetchQueueItems = () => {
    getAdminLegacyQueueItemsCall()
      .then((response: LegacyQueueResponse) => {
        setQueueItems(response.jobs);
        setStatus(response.is_running ? 'running' : 'idle');
      })
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    const interval = setInterval(() => {
      fetchQueueItems();
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    // Initial load, without waiting 5 sec from interval.
    fetchQueueItems();
  }, []);

  return (
    <CronJobCard
      title={`${t('legacyQueue')} (${queueItems.length})`}
      controls={
        <Typography fontWeight={800} color={status === 'idle' ? 'brandGreen' : 'error'}>
          {t(status)}
        </Typography>
      }
      isLoading={isLoading}
    >
      {queueItems.length === 0 && <JobItemsEmptyState />}

      <Grid container spacing={2}>
        {queueItems.length !== 0 && (
          <>
            <Grid item xs={2}>
              <Typography variant={'button'}>{t('id')}</Typography>
              {t('id')}
            </Grid>
            <Grid item xs={8}>
              <Typography variant={'button'}>{t('action')}</Typography>
            </Grid>
            <Grid item xs={2} textAlign={'right'}>
              <Typography variant={'button'}>{t('createdAt')}</Typography>
            </Grid>
          </>
        )}

        {queueItems.map((item) => (
          <>
            <Grid item xs={2}>
              {item.id}
            </Grid>

            <Grid item xs={6}>
              <Tooltip title={JSON.stringify(item.payload)} key={item.id}>
                <Box>{item.action}</Box>
              </Tooltip>
            </Grid>

            <Grid item xs={4} textAlign={'right'}>
              {dayjs(item.created_at).format('YYYY-MM-DD HH:mm:ss')}
            </Grid>
          </>
        ))}
      </Grid>
    </CronJobCard>
  );
};

export default LegacyQueuedJobsCard;
