import paths from '../../paths';
import React, { lazy } from 'react';
import {
  fetchOneWidgetIntegrationCall,
  fetchWidgetIntegrationsCall,
} from 'api/app/restaurant/widgetIntegration';
import { getPoliciesTermsCall } from 'api/app/restaurant/settings/policiesAndTerms';
import { Guard } from 'app/components/Security/Guard';
import { Access } from 'hooks/useGuard';
import GenericError from 'app/components/ErrorDisplay/GenericError';
import { getWidgetFieldsCall } from 'api/app/restaurant/widgetFields';
import { LoaderFunctionArgs } from 'react-router-dom';
import {
  getAdminWidgetThemeGroupIndexCall,
  getAdminWidgetThemeIndexCall,
} from 'api/app/admin/WidgetTheme';

const PoliciesAndTermsPage = lazy(() => import('app/pages/RestaurantSettings/PoliciesAndTerms'));
const WidgetSettingsPage = lazy(() => import('app/pages/RestaurantSettings/WidgetSettings'));
const WidgetFields = lazy(() => import('app/pages/RestaurantSettings/WidgetFields'));
const WidgetIntegrationIndexPage = lazy(
  () => import('app/pages/RestaurantSettings/WidgetIntegration/WidgetIntegrationIndex'),
);
const WidgetIntegrationFormPage = lazy(
  () => import('app/pages/RestaurantSettings/WidgetIntegration/WidgetIntegrationForm'),
);

const onlineReservations = [
  {
    path: paths.settings.onlineReservations.widgetSettings,
    element: (
      <Guard
        access={Access.ManageWidgetSettings}
        component={<WidgetSettingsPage />}
        fallbackComponent={<GenericError status={403} />}
      />
    ),
  },
  {
    path: paths.settings.onlineReservations.widgetFields,
    loader: async () => await getWidgetFieldsCall(),
    element: <WidgetFields />,
  },
  {
    path: paths.settings.onlineReservations.policiesAndTerms,
    loader: async () => await getPoliciesTermsCall(),
    element: <PoliciesAndTermsPage />,
  },
  {
    path: paths.settings.onlineReservations.widgetIntegration.index,
    loader: async () => await fetchWidgetIntegrationsCall(),
    element: <WidgetIntegrationIndexPage />,
  },
  {
    path: paths.settings.onlineReservations.widgetIntegration.edit,
    loader: async ({ params }: LoaderFunctionArgs) => {
      const id = Number(params?.id);

      if (isNaN(id)) {
        throw new Response('Not found', { status: 404 });
      }

      return Promise.all([
        fetchOneWidgetIntegrationCall(id),
        getAdminWidgetThemeIndexCall(),
        getAdminWidgetThemeGroupIndexCall(),
      ]);
    },
    element: <WidgetIntegrationFormPage />,
  },
  {
    path: paths.settings.onlineReservations.widgetIntegration.create,
    loader: async () => {
      return Promise.all([
        null,
        getAdminWidgetThemeIndexCall(),
        getAdminWidgetThemeGroupIndexCall(),
      ]);
    },
    element: <WidgetIntegrationFormPage />,
  },
];

export default onlineReservations;
