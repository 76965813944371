import paths from 'app/router/paths';
import { lazy } from 'react';
import { LoaderFunctionArgs } from 'react-router-dom';
import { queryStringToObject } from 'utils/http/queryStringToObject';
import { getAdminRestaurantCall, getAdminRestaurantsIndexCall } from 'api/app/admin/restaurant';
import { getRestaurantConfigEnumOptionsCall } from 'api/app/admin/system/restaurantConfigEnumOptions';

const RestaurantListPage = lazy(
  () => import('app/pages/Admin/Entities/Restaurants/RestaurantIndex'),
);
const RestaurantEditPage = lazy(
  () => import('app/pages/Admin/Entities/Restaurants/RestaurantEdit'),
);

const adminRestaurants = [
  {
    path: paths.admin.restaurant.index,
    element: <RestaurantListPage />,
    loader: async (loader: LoaderFunctionArgs) => {
      const searchQuery = queryStringToObject(loader.request.url);

      return Promise.all([
        getAdminRestaurantsIndexCall(searchQuery),
        getRestaurantConfigEnumOptionsCall(),
      ]);
    },
  },
  {
    path: paths.admin.restaurant.edit,
    element: <RestaurantEditPage />,
    loader: async ({ params }: LoaderFunctionArgs) => {
      const id = Number(params?.id);

      if (isNaN(id)) {
        throw new Response('Not found', { status: 404 });
      }

      return Promise.all([getAdminRestaurantCall(id), getRestaurantConfigEnumOptionsCall()]);
    },
  },
];

export default adminRestaurants;
