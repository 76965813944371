import { NavLink, useLoaderData } from 'react-router-dom';
import { PaginatedResponse } from 'types/app/paginatedResponse';
import { StyledTableCell } from 'app/pages/RestaurantSettings/_components/StyledTableCell';
import { SettingsFormWrapper } from 'app/components/SettingsFormWrapper';
import { ColumnWrapper } from 'app/pages/RestaurantSettings/_components/ColumnWrapper';
import { StyledCard } from 'app/components/StyledComponents/StyledCard';
import { CustomTable } from 'app/pages/RestaurantSettings/_components/CustomTable';
import Pagination from 'app/components/Navigation/Pagination';

import { AdminWidgetTheme } from 'types/admin/WidgetIntegration';
import WidgetThemeRowItem from './_components/WidgetThemeRowItem';
import paths from 'app/router/paths';
import { Button } from '@mui/material';

const WidgetThemeIndex = () => {
  const themes = useLoaderData() as PaginatedResponse<AdminWidgetTheme[]>;
  const tableHeadRows = ['id', 'Title', 'Group', 'Actions'];

  const tableHead = tableHeadRows.map((row) => <StyledTableCell key={row}>{row}</StyledTableCell>);

  const tableRows = themes?.data.map((theme) => (
    <WidgetThemeRowItem widget_theme={theme} key={theme.id} />
  ));

  return (
    <SettingsFormWrapper
      title={'Widget Theme'}
      withoutSaveButton
      additionalElements={
        <Button
          variant="outlined"
          color="secondary"
          component={NavLink}
          to={paths.admin.theme.create}
        >
          Create new
        </Button>
      }
    >
      <ColumnWrapper>
        <StyledCard withSpacing>
          <CustomTable scrollableX headRowsToRender={tableHead} rowsToRender={tableRows} />
          <Pagination data={themes} />
        </StyledCard>
      </ColumnWrapper>
    </SettingsFormWrapper>
  );
};

export default WidgetThemeIndex;
