import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

const JobItemsEmptyState = () => {
  const { t } = useTranslation();

  return (
    <Box display={'flex'} justifyContent={'center'} alignItems={'center'} sx={{ height: 350 }}>
      <Typography fontSize={18} fontWeight={800}>
        {t('noJobsInQueue')}
      </Typography>
    </Box>
  );
};

export default JobItemsEmptyState;
