import { ReactNode } from 'react';
import { Box, SxProps } from '@mui/material';

interface OwnProps {
  children: ReactNode;
  sx?: SxProps;
}

export const ColumnWrapper = ({ children, sx }: OwnProps) => {
  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: '12px',
        position: 'relative',
        ...sx,
      }}
    >
      {children}
    </Box>
  );
};
