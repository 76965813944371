import CronJobCard from 'app/pages/Admin/Application/Cron/_components/CronJobCard';
import { useEffect, useState } from 'react';
import { getAdminMailQueueItemsCall } from 'api/app/admin/application/cron';
import { MailQueueJobItem } from 'app/pages/Admin/Application/Cron/index';
import JobItemsEmptyState from 'app/pages/Admin/Application/Cron/_components/JobItemsEmptyState';
import { Button, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import paths from 'app/router/paths';
import { NavLink } from 'react-router-dom';
import dayjs from 'dayjs';

const MailQueueJobsCard = () => {
  const { t } = useTranslation();
  const [jobs, setJobs] = useState<MailQueueJobItem[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    getAdminMailQueueItemsCall()
      .then((response) => setJobs(response))
      .finally(() => setIsLoading(false));
  }, []);

  return (
    <CronJobCard title={`${t('mailQueue')} (${jobs.length})`} isLoading={isLoading}>
      {jobs.length === 0 && <JobItemsEmptyState />}

      <Grid container spacing={2}>
        {jobs.length !== 0 && (
          <>
            <Grid item xs={2}>
              <Typography variant={'button'}>{t('id')}</Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography variant={'button'}>{t('priority')}</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant={'button'}>{t('sendAt')}</Typography>
            </Grid>
            <Grid item xs={2} />
          </>
        )}

        {jobs.map((item) => (
          <>
            <Grid item xs={2}>
              {item.id}
            </Grid>

            <Grid item xs={2}>
              {item.priority}
            </Grid>

            <Grid item xs={6}>
              {item.send_at ? dayjs(item.send_at).format('YYYY-MM-DD HH:mm') : '-'}
            </Grid>

            <Grid item xs={2} textAlign={'right'}>
              <Button
                component={NavLink}
                to={`${paths.admin.logs.emailLogs}/${item.log_id}`}
                size={'small'}
                color={'secondary'}
              >
                {t('preview')}
              </Button>
            </Grid>
          </>
        ))}
      </Grid>
    </CronJobCard>
  );
};

export default MailQueueJobsCard;
