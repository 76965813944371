import { SettingsFormWrapper } from 'app/components/SettingsFormWrapper';
import { Grid } from '@mui/material';
import LegacyQueuedJobsCard from 'app/pages/Admin/Application/Cron/_components/LegacyQueuedJobsCard';
import LegacyFailedJobsCard from 'app/pages/Admin/Application/Cron/_components/LegacyFailedJobsCard';
import MailQueueJobsCard from 'app/pages/Admin/Application/Cron/_components/MailQueueJobsCard';
import ScheduleJobsCard from 'app/pages/Admin/Application/Cron/_components/ScheduleJobsCard';

export type LegacyQueueJobItem = {
  id: number;
  action: string;
  payload: object;
  priority: number;
  created_at: string;
};

export type MailQueueJobItem = {
  id: number;
  log_id: number | null;
  priority: number;
  send_at: string | null;
};

export type ScheduleJobItem = {
  id: number;
  execute_at: string;
  unique_identifier: string;
};

const Cron = () => {
  return (
    <SettingsFormWrapper title={'CRON'} withoutSaveButton>
      <Grid container spacing={2}>
        <Grid item xs={12} xl={6}>
          <LegacyQueuedJobsCard />
        </Grid>

        <Grid item xs={12} xl={6}>
          <LegacyFailedJobsCard />
        </Grid>

        <Grid item xs={12} xl={6}>
          <MailQueueJobsCard />
        </Grid>

        <Grid item xs={12} xl={6}>
          <ScheduleJobsCard />
        </Grid>
      </Grid>
    </SettingsFormWrapper>
  );
};

export default Cron;
