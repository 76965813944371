import CronJobCard from 'app/pages/Admin/Application/Cron/_components/CronJobCard';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { ScheduleJobItem } from 'app/pages/Admin/Application/Cron/index';
import { getAdminScheduleJobItemsCall } from 'api/app/admin/application/cron';
import JobItemsEmptyState from 'app/pages/Admin/Application/Cron/_components/JobItemsEmptyState';
import { Grid, Typography } from '@mui/material';
import dayjs from 'dayjs';

const ScheduleJobsCard = () => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const [jobs, setJobs] = useState<ScheduleJobItem[]>([]);

  useEffect(() => {
    getAdminScheduleJobItemsCall()
      .then((response) => setJobs(response))
      .finally(() => setIsLoading(false));
  }, []);

  return (
    <CronJobCard title={`${t('scheduleJobs')} (${jobs.length})`} isLoading={isLoading}>
      {jobs.length === 0 && <JobItemsEmptyState />}

      <Grid container spacing={2}>
        {jobs.length !== 0 && (
          <>
            <Grid item xs={2}>
              <Typography variant={'button'}>{t('id')}</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant={'button'}>{t('identifier')}</Typography>
            </Grid>
            <Grid item xs={4} textAlign={'right'}>
              <Typography variant={'button'}>{t('executeAt')}</Typography>
            </Grid>
          </>
        )}

        {jobs.map((item) => (
          <>
            <Grid item xs={2}>
              {item.id}
            </Grid>

            <Grid item xs={6}>
              {item.unique_identifier}
            </Grid>

            <Grid item xs={4} textAlign={'right'}>
              {dayjs(item.execute_at).format('YYYY-MM-DD HH:mm')}
            </Grid>
          </>
        ))}
      </Grid>
    </CronJobCard>
  );
};

export default ScheduleJobsCard;
