import { NavLink, useLoaderData } from 'react-router-dom';
import { PaginatedResponse } from 'types/app/paginatedResponse';
import { StyledTableCell } from 'app/pages/RestaurantSettings/_components/StyledTableCell';
import { SettingsFormWrapper } from 'app/components/SettingsFormWrapper';
import { ColumnWrapper } from 'app/pages/RestaurantSettings/_components/ColumnWrapper';
import { StyledCard } from 'app/components/StyledComponents/StyledCard';
import { CustomTable } from 'app/pages/RestaurantSettings/_components/CustomTable';
import Pagination from 'app/components/Navigation/Pagination';

import { AdminWidgetGroup } from 'types/admin/WidgetIntegration';
import WidgetGroupRowItem from './_components/WidgetGroupRowItem';
import paths from 'app/router/paths';
import { Button } from '@mui/material';

const WidgetThemeGroupIndex = () => {
  const theme_groups = useLoaderData() as PaginatedResponse<AdminWidgetGroup[]>;

  const tableHeadRows = ['id', 'Title', 'Description', 'Actions'];

  const tableHead = tableHeadRows.map((row) => <StyledTableCell key={row}>{row}</StyledTableCell>);

  const tableRows = theme_groups?.data.map((group) => (
    <WidgetGroupRowItem widget_group={group} key={group.id} />
  ));

  return (
    <SettingsFormWrapper
      title={'Widget Theme Groups'}
      withoutSaveButton
      additionalElements={
        <Button
          variant="outlined"
          color="secondary"
          component={NavLink}
          to={paths.admin.themeGroup.create}
        >
          Create new
        </Button>
      }
    >
      <ColumnWrapper>
        <StyledCard withSpacing>
          <CustomTable scrollableX headRowsToRender={tableHead} rowsToRender={tableRows} />
          <Pagination data={theme_groups} />
        </StyledCard>
      </ColumnWrapper>
    </SettingsFormWrapper>
  );
};

export default WidgetThemeGroupIndex;
