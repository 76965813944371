import { styled } from '@mui/material/styles';
import { TableRow } from '@mui/material';

interface Props {
  clickable?: boolean;
}

export const StyledTableRow = styled(TableRow, {
  shouldForwardProp: (prop) => prop !== 'clickable',
})<Props>(({ clickable, theme }) => ({
  cursor: clickable ? 'pointer' : 'initial',
  '&:not(:last-child) td, &:not(:last-child) th': {
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
  },
  '& td, & th': {
    fontSize: '14px',
    color: 'comet',
  },
  '&:hover': {
    backgroundColor: theme.palette.zircon,
  },
}));
