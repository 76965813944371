import { Button, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useRef, useState } from 'react';
import { SettingsViewWrapper } from 'app/pages/RestaurantSettings/_components/SettingsViewWrapper';
import { SettingHeader } from 'app/components/SettingHeader';
import { ServiceModal } from 'app/pages/RestaurantSettings/TimeManagement/_components/ServiceModal';
import timeManagementActions from 'redux/actions/app/timeManagement';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { FormCard } from 'app/pages/RestaurantSettings/TimeManagement/_components/FormCard';
import { enqueueSnackbar } from 'notistack';
import { MessageVariants } from 'enums/notifications';
import { CustomBookingLength } from 'types/app/timeManagement';
import { OrderModal } from 'app/components/Modals/OrderModal';

const TimeManagementPage = () => {
  const [isServiceModalOpen, setIsServiceModalOpen] = useState(false);
  const [isOrderModalOpen, setIsOrderModalOpen] = useState(false);
  const { t } = useTranslation();
  const scrollRef = useRef(null);
  const dispatch = useAppDispatch();

  const { custom_booking_length, default_booking_length } = useAppSelector(
    (state) => state.app.settings.time_management,
  );

  const handleOrderSubmit = (order: number[]) => {
    setIsOrderModalOpen(false);

    (async () => {
      try {
        await dispatch(timeManagementActions.updateCustomBookingLengthOrder(order));
        await dispatch(timeManagementActions.getCustomBookingLength());

        enqueueSnackbar(t('updateRoomsOrderSuccess'), { variant: MessageVariants.Success });
      } catch {}
    })();
  };

  const handleDelete = async (data: CustomBookingLength) => {
    dispatch(timeManagementActions.deleteCustomBookingLengthById(data.id)).then(() => {
      enqueueSnackbar(t('deleteCustomBookingLengthSuccess'), { variant: MessageVariants.Success });
    });
  };

  return (
    <>
      <SettingsViewWrapper scrollRef={scrollRef}>
        <SettingHeader
          scrollRef={scrollRef}
          title={t('timeManagementTitle')}
          withoutSaveButton={true}
          additionalElements={
            <>
              <Button
                variant="outlined"
                color="secondary"
                onClick={() => setIsOrderModalOpen(true)}
                disabled={custom_booking_length.length < 2}
              >
                {t('changeServiceOrderButton')}
              </Button>
              <Button
                variant="contained"
                color="secondary"
                onClick={() => setIsServiceModalOpen(true)}
              >
                {t('addService')}
              </Button>
            </>
          }
        />
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '24px' }}>
          <FormCard
            bookingLength={{
              name: t('timeManagementDefaultReservation'),
              ranges: default_booking_length.ranges,
            }}
          />
          <Box sx={{ position: 'relative' }}>
            {custom_booking_length.map((bookingLength, index) => (
              <FormCard
                key={bookingLength.id}
                id={bookingLength.id}
                index={index}
                bookingLength={bookingLength}
                isCustom={true}
                onDelete={handleDelete}
              />
            ))}
          </Box>
        </Box>
      </SettingsViewWrapper>
      <ServiceModal isOpen={isServiceModalOpen} onClose={() => setIsServiceModalOpen(false)} />
      <OrderModal
        open={isOrderModalOpen}
        onClose={() => setIsOrderModalOpen(false)}
        onSave={handleOrderSubmit}
        items={custom_booking_length.map((bookingLength) => ({
          id: bookingLength?.id || 0,
          name: bookingLength.name,
        }))}
      ></OrderModal>
    </>
  );
};

export default TimeManagementPage;
