import CronJobCard from 'app/pages/Admin/Application/Cron/_components/CronJobCard';
import { useEffect, useState } from 'react';
import {
  deleteAdminLegacyQueueItemCall,
  forceRunAdminLegacyQueueCall,
  getAdminLegacyFailedQueueItemsCall,
} from 'api/app/admin/application/cron';
import { useTranslation } from 'react-i18next';
import JobItemsEmptyState from 'app/pages/Admin/Application/Cron/_components/JobItemsEmptyState';
import { Box, Grid, Tooltip, Typography } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { LoadingButton } from '@mui/lab';
import { LegacyQueueJobItem } from 'app/pages/Admin/Application/Cron/index';

const LegacyFailedJobsCard = () => {
  const { t } = useTranslation();
  const [queueItems, setQueueItems] = useState<LegacyQueueJobItem[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [removalIsLoading, setRemovalIsLoading] = useState<number | false>(false);

  const fetchQueueItems = () => {
    getAdminLegacyFailedQueueItemsCall()
      .then((response) => setQueueItems(response))
      .finally(() => setIsLoading(false));
  };

  const handleJobRemoval = async (jobId: number) => {
    setRemovalIsLoading(jobId);
    await deleteAdminLegacyQueueItemCall(jobId);
    fetchQueueItems();
  };

  const handleJobsForceRun = async () => {
    setIsLoading(true);
    await forceRunAdminLegacyQueueCall();
    fetchQueueItems();
  };

  useEffect(() => {
    fetchQueueItems();
  }, []);

  return (
    <CronJobCard
      title={`${t('failedJobs')} (${queueItems.length})`}
      isLoading={isLoading}
      controls={
        <LoadingButton
          disabled={queueItems.length === 0}
          color={'secondary'}
          onClick={handleJobsForceRun}
        >
          {t('forceRun')}
        </LoadingButton>
      }
    >
      {queueItems.length === 0 && <JobItemsEmptyState />}

      <Grid container spacing={2}>
        {queueItems.length !== 0 && (
          <>
            <Grid item xs={2}>
              <Typography variant={'button'}>{t('id')}</Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography variant={'button'}>{t('action')}</Typography>
            </Grid>
            <Grid item xs={2} />
          </>
        )}

        {queueItems.map((item) => (
          <>
            <Grid item xs={2}>
              {item.id}
            </Grid>

            <Grid item xs={8}>
              <Tooltip title={JSON.stringify(item.payload)} key={item.id}>
                <Box>{item.action}</Box>
              </Tooltip>
            </Grid>

            <Grid item xs={2} textAlign={'right'}>
              <LoadingButton size={'small'} color={'error'} loading={removalIsLoading === item.id}>
                <DeleteIcon onClick={() => handleJobRemoval(item.id)} />
              </LoadingButton>
            </Grid>
          </>
        ))}
      </Grid>
    </CronJobCard>
  );
};

export default LegacyFailedJobsCard;
