import { StyledTableRow } from 'app/pages/RestaurantSettings/_components/StyledTableRow';
import { IconButton, ButtonGroup, TableCell } from '@mui/material';
import { NavLink, useRevalidator } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import RemoveIcon from '@mui/icons-material/Remove';
import paths from 'app/router/paths';
import { AdminWidgetTheme } from 'types/admin/WidgetIntegration';
import { deleteAdminWidgetThemeCall } from 'api/app/admin/WidgetTheme';
import { useState } from 'react';
import { LoadingOverlay } from 'app/components/Overlays/LoadingOverlay';

type Props = {
  widget_theme: AdminWidgetTheme;
};

const WidgetThemeRowItem = ({ widget_theme }: Props) => {
  const revalidator = useRevalidator();
  const [isLoading, setIsLoading] = useState(false);

  return (
    <>
      <LoadingOverlay isLoading={isLoading} />
      <StyledTableRow>
        <TableCell>{widget_theme.id}</TableCell>
        <TableCell>{widget_theme.name}</TableCell>
        <TableCell>{widget_theme.group_id}</TableCell>
        <TableCell>
          <ButtonGroup>
            <IconButton
              color="secondary"
              component={NavLink}
              to={paths.admin.theme.edit.replace(':id', widget_theme.id.toString())}
            >
              <EditIcon />
            </IconButton>
            <IconButton
              color="error"
              onClick={() => {
                setIsLoading(true);
                deleteAdminWidgetThemeCall(widget_theme.id)
                  .then(() => {
                    revalidator.revalidate();
                  })
                  .finally(() => {
                    setIsLoading(false);
                  });
              }}
            >
              <RemoveIcon />
            </IconButton>
          </ButtonGroup>
        </TableCell>
      </StyledTableRow>
    </>
  );
};

export default WidgetThemeRowItem;
