import paths from '../../paths';
import { lazy } from 'react';
import { Guard } from 'app/components/Security/Guard';
import { Access } from 'hooks/useGuard';
import GenericError from 'app/components/ErrorDisplay/GenericError';
import TimeManagement from 'app/pages/RestaurantSettings/TimeManagement';
import store from 'redux/store';
import timeManagementActions from 'redux/actions/app/timeManagement';
const dispatch = store.dispatch;

const TableManagementSettingsPage = lazy(
  () => import('app/pages/RestaurantSettings/TableManagement'),
);
const TableGroupsPage = lazy(() => import('app/pages/RestaurantSettings/TableGroups'));
const DiningAreasPage = lazy(
  () => import('app/pages/RestaurantSettings/TableManagement/_components/DiningAreas'),
);

const tableSettings = [
  {
    path: paths.settings.tableSettings.tableManagement,
    element: (
      <Guard
        access={Access.ManageDiningRoomsAndAreas}
        component={<TableManagementSettingsPage />}
        fallbackComponent={<GenericError status={403} />}
      />
    ),
  },
  {
    path: paths.settings.tableSettings.tableGroups,
    element: (
      <Guard
        access={Access.ManageTableGroups}
        component={<TableGroupsPage />}
        fallbackComponent={<GenericError status={403} />}
      />
    ),
  },
  {
    path: paths.settings.accountSetup.timeManagement,
    loader: () => {
      return Promise.all([
        dispatch(timeManagementActions.getDefaultBookingLength()),
        dispatch(timeManagementActions.getCustomBookingLength()),
      ]);
    },
    element: (
      <Guard
        access={Access.ManageReservationDuration}
        component={<TimeManagement />}
        fallbackComponent={<GenericError status={403} />}
      />
    ),
  },
  {
    path: paths.settings.tableSettings.diningAreas,
    element: (
      <Guard
        access={Access.ManageDiningRoomsAndAreas}
        component={<DiningAreasPage />}
        fallbackComponent={<GenericError status={403} />}
      />
    ),
  },
];

export default tableSettings;
