import { ColumnWrapper } from 'app/pages/RestaurantSettings/_components/ColumnWrapper';
import { StyledCard } from 'app/components/StyledComponents/StyledCard';
import { Box, CircularProgress, Typography } from '@mui/material';
import { ReactNode } from 'react';

type Props = {
  title: string;
  children: ReactNode;
  controls?: ReactNode;
  isLoading: boolean;
};

const CronJobCard = ({ title, children, controls, isLoading }: Props) => {
  return (
    <ColumnWrapper>
      <StyledCard>
        <Box sx={{ height: 400, overflow: 'scroll' }}>
          {isLoading ? (
            <Box
              display={'flex'}
              justifyContent={'center'}
              alignItems={'center'}
              sx={{ height: 380 }}
            >
              <CircularProgress />
            </Box>
          ) : (
            <>
              <Box mb={2} display={'flex'} justifyContent={'space-between'}>
                <Typography fontWeight={800}>{title}</Typography>
                {controls}
              </Box>

              {children}
            </>
          )}
        </Box>
      </StyledCard>
    </ColumnWrapper>
  );
};

export default CronJobCard;
