import paths from 'app/router/paths';
import { lazy } from 'react';
import { getPeriodOpeningHours, getWeekdayOpeningHours } from 'api/app/restaurant/openingHours';

const OpeningHoursWeekdaySettings = lazy(
  () => import('app/pages/RestaurantSettings/ReservationDuration/OpeningHours/Weekday'),
);

const openingHours = [
  {
    path: paths.settings.openingHours.weekdaySettings,
    loader: async () => await getWeekdayOpeningHours(),
    element: <OpeningHoursWeekdaySettings />,
  },
  {
    path: paths.settings.openingHours.periodSetting,
    loader: async () => await getPeriodOpeningHours(),
    element: <OpeningHoursWeekdaySettings />,
  },
];

export default openingHours;
