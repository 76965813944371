import DashboardIcon from '@mui/icons-material/Dashboard';
import DocumentScannerIcon from '@mui/icons-material/DocumentScanner';
import RestaurantIcon from '@mui/icons-material/Restaurant';
import paths from 'app/router/paths';
import { SettingsMenuSection } from 'app/components/Layout/SettingsWrapper/_components/SettingsMenu';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import BugReportOutlinedIcon from '@mui/icons-material/BugReportOutlined';
import SdStorageIcon from '@mui/icons-material/SdStorage';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import SnippetFolderIcon from '@mui/icons-material/SnippetFolder';
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';

const adminSettingsSubNavigationLinks: SettingsMenuSection[] = [
  {
    title: 'Dashboard',
    links: [
      {
        icon: <DashboardIcon />,
        to: paths.admin.dashboard,
        name: 'Home',
      },
    ],
  },
  {
    title: 'Entities',
    links: [
      {
        icon: <RestaurantIcon />,
        to: paths.admin.restaurant.index,
        name: 'Restaurants',
      },
    ],
  },
  {
    title: 'Widget themes',
    links: [
      {
        icon: <ArticleOutlinedIcon />,
        to: paths.admin.theme.index,
        name: 'Themes',
      },
      {
        icon: <SnippetFolderIcon />,
        to: paths.admin.themeGroup.index,
        name: 'Theme groups',
      },
    ],
  },
  {
    title: 'Emails',
    links: [
      {
        icon: <DocumentScannerIcon />,
        to: paths.admin.email.emailTemplates,
        name: 'Email templates',
      },
    ],
  },
  {
    title: 'Logs',
    links: [
      {
        icon: <MailOutlineIcon />,
        to: paths.admin.logs.emailLogs,
        name: 'Send mail logs',
      },
      {
        icon: <BugReportOutlinedIcon />,
        to: paths.admin.logs.errorLogs,
        name: 'Error logs',
      },
    ],
  },
  {
    title: 'Application',
    links: [
      {
        icon: <SdStorageIcon />,
        to: paths.admin.application.dbCache,
        name: 'DB Cache',
      },
      {
        icon: <AutorenewIcon />,
        to: paths.admin.application.cron,
        name: 'Cron',
      },
    ],
  },
];

export default adminSettingsSubNavigationLinks;
