import adminEmailSettings from 'app/router/routes/admin/adminEmailSettings';
import paths from 'app/router/paths';
import { lazy } from 'react';
import adminLogs from 'app/router/routes/admin/adminLogs';
import adminApplication from 'app/router/routes/admin/adminApplication';
import entities from 'app/router/routes/admin/entities';
import themeSettings from 'app/router/routes/admin/themeSettings';

const AdminDashboardPage = lazy(() => import('app/pages/Admin/Dashboard'));

const admin = [
  {
    path: paths.admin.dashboard,
    element: <AdminDashboardPage />,
  },
  ...adminEmailSettings,
  ...adminLogs,
  ...adminApplication,
  ...entities,
  ...themeSettings,
];

export default admin;
