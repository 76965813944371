import { lazy } from 'react';
import paths from 'app/router/paths';
import { LoaderFunctionArgs } from 'react-router-dom';
import { queryStringToObject } from 'utils/http/queryStringToObject';
import {
  getAdminWidgetThemeGroupIndexCall,
  getAdminWidgetThemeIndexCall,
  getOneAdminWidgetThemeCall,
  getOneAdminWidgetThemeGroupCall,
} from 'api/app/admin/WidgetTheme';
import { getAdminSystemWidgetGroupNamesCall } from 'api/app/admin/system/widgetGroupNames';
import WidgetThemeIndex from 'app/pages/Admin/WidgetThemes/WidgetTheme/WidgetThemeIndex';
import WidgetThemeGroupIndex from 'app/pages/Admin/WidgetThemes/WidgetGroup/GroupIndex';

const WidgetThemeFormPage = lazy(
  () => import('app/pages/Admin/WidgetThemes/WidgetTheme/WidgetThemeForm'),
);
const GroupFormPage = lazy(() => import('app/pages/Admin/WidgetThemes/WidgetGroup/GroupForm'));

const adminWidgetSettings = [
  {
    path: paths.admin.theme.index,
    loader: async ({ request }: LoaderFunctionArgs) => {
      const searchQuery = queryStringToObject(request.url);

      return await getAdminWidgetThemeIndexCall(searchQuery);
    },
    element: <WidgetThemeIndex />,
  },
  {
    path: paths.admin.theme.create,
    loader: async () => {
      return [await getAdminSystemWidgetGroupNamesCall()];
    },
    element: <WidgetThemeFormPage />,
  },
  {
    path: paths.admin.theme.edit,
    loader: async ({ params }: LoaderFunctionArgs) => {
      const id = Number(params?.id);

      if (isNaN(id)) {
        throw new Response('Not found', { status: 404 });
      }

      return Promise.all([getAdminSystemWidgetGroupNamesCall(), getOneAdminWidgetThemeCall(id)]);
    },
    element: <WidgetThemeFormPage />,
  },
  {
    path: paths.admin.themeGroup.index,
    loader: async ({ request }: LoaderFunctionArgs) => {
      const searchQuery = queryStringToObject(request.url);

      return await getAdminWidgetThemeGroupIndexCall(searchQuery);
    },
    element: <WidgetThemeGroupIndex />,
  },
  {
    path: paths.admin.themeGroup.create,
    element: <GroupFormPage />,
  },
  {
    path: paths.admin.themeGroup.edit,
    loader: async ({ params }: LoaderFunctionArgs) => {
      const id = Number(params?.id);

      if (isNaN(id)) {
        throw new Response('Not found', { status: 404 });
      }

      return await getOneAdminWidgetThemeGroupCall(id);
    },
    element: <GroupFormPage />,
  },
];

export default adminWidgetSettings;
