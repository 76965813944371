import paths from 'app/router/paths';
import { lazy } from 'react';
import Cron from 'app/pages/Admin/Application/Cron';

const DbCachePage = lazy(() => import('app/pages/Admin/Application/DbCache'));

const adminApplication = [
  {
    path: paths.admin.application.dbCache,
    element: <DbCachePage />,
  },
  {
    path: paths.admin.application.cron,
    element: <Cron />,
  },
];

export default adminApplication;
