import { StyledTableRow } from 'app/pages/RestaurantSettings/_components/StyledTableRow';
import { IconButton, ButtonGroup, TableCell } from '@mui/material';
import { NavLink, useRevalidator } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import RemoveIcon from '@mui/icons-material/Remove';
import paths from 'app/router/paths';
import { AdminWidgetGroup } from 'types/admin/WidgetIntegration';
import { deleteAdminWidgetThemeGroupCall } from 'api/app/admin/WidgetTheme';
import { LoadingOverlay } from 'app/components/Overlays/LoadingOverlay';
import { useState } from 'react';

type Props = {
  widget_group: AdminWidgetGroup;
};

const WidgetGroupRowItem = ({ widget_group }: Props) => {
  const revalidator = useRevalidator();
  const [isLoading, setIsLoading] = useState(false);

  return (
    <>
      <LoadingOverlay isLoading={isLoading} />
      <StyledTableRow>
        <TableCell>{widget_group.id}</TableCell>
        <TableCell>{widget_group.title}</TableCell>
        <TableCell>{widget_group.description}</TableCell>
        <TableCell>
          <ButtonGroup>
            <IconButton
              color="secondary"
              component={NavLink}
              to={paths.admin.themeGroup.edit.replace(':id', widget_group.id.toString())}
            >
              <EditIcon />
            </IconButton>
            <IconButton
              color="error"
              onClick={() => {
                setIsLoading(true);
                deleteAdminWidgetThemeGroupCall(widget_group.id)
                  .then(() => {
                    revalidator.revalidate();
                  })
                  .finally(() => {
                    setIsLoading(false);
                  });
              }}
            >
              <RemoveIcon />
            </IconButton>
          </ButtonGroup>
        </TableCell>
      </StyledTableRow>
    </>
  );
};

export default WidgetGroupRowItem;
